<template>
  <v-card class="neutral rounded-xl">
    <v-card-title primary-title>
      <v-icon
        v-if="closeId"
        :id="closeId"
        class="close-btn"
        @click="close()"
      >
        close
      </v-icon>
      <span v-if="title">
        <v-icon
          v-if="titleIcon"
          class="mr-2"
        >
          {{ titleIcon }}
        </v-icon>{{ title }}
      </span>
    </v-card-title>
    <v-card-text class="pt-3">
      <p
        v-if="secondTitle"
      >
        {{ secondTitle }}
      </p>
      <span
        v-if="text"
        class="mt-4"
      >
        <v-icon v-if="icon">
          {{ icon }}
        </v-icon>{{ text }}
      </span>
      <div>
        <slot name="custom" />
        <slot name="form" />
        <slot name="text" />
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <slot name="buttons" />
      <v-btn
        v-if="closeButton"
        :id="closeButton.id"
        text
        rounded
        @click="handleClose()"
      >
        {{ closeButton.text }}
      </v-btn>
      <v-btn
        v-if="actionButton"
        :id="actionButton.id"
        :loading="actionButton.loading"
        :class="actionButton.class"
        text
        rounded
        @click="handleAction()"
      >
        {{ actionButton.text }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'CustomDialog',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    secondTitle: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    titleIcon: {
      type: String,
      default: () => ''
    },
    closeId: {
      type: String,
      default: () => ''
    },
    dialog: {
      type: String,
      default: () => ''
    },
    closeButton: {
      type: Object,
      default: () => {}
    },
    actionButton: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleClose () {
      this.$emit('close-event')
    },
    handleAction () {
      this.$emit('perform-action')
    }
  }
}
</script>
